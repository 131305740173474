<!--充值套餐概况-->
<template>
  <div id="packageOverview">
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column label="套餐名称" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.packageName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="套餐创建时间"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.addDt }}</span>
        </template>
      </el-table-column>
      <el-table-column label="总充值额" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.totalRechargeAmount }}</span>
        </template>
      </el-table-column>
      <el-table-column label="总赠金额" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.totalGiveAmount }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="赠金已使用/赠金未使用"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span
            >{{ scope.row.giveAmountUse }}/{{
              scope.row.giveAmountNotUse
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="总充值用户" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.totalRechargeCount }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="重复充值用户"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.repeatRechargeCount }}</span>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click.stop="goClick(scope.row)"
            >充值明细</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="table-footer">
      <el-pagination
        layout="total,sizes, prev, pager, next,jumper"
        background
        :total="total"
        :page-size="tableParams.size"
        @size-change="onSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage"
        @current-change="onCurrentPage"
      >
      </el-pagination>
    </div>
    <!--新增充值套餐弹框-->
    <el-dialog
      :visible.sync="detailShow"
      title="充值明细"
      :close-on-click-modal="false"
      width="800px"
      custom-class="add-dialog"
    >
      <div style="display: flex">
        <el-input
          v-model="itemParams.kw"
          placeholder="请输入昵称 / 手机号搜索"
          @input="kwChange"
          clearable
          style="margin: 0 20px 20px 0; width: 220px"
        />

        <el-date-picker
          v-model="dateToDate"
          type="daterange"
          align="center"
          unlink-panels
          range-separator="~"
          start-placeholder="充值时间"
          end-placeholder="充值时间"
          :picker-options="pickerOptions"
          @change="goSelectDate()"
          clearable
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <el-table
        :data="itemData"
        border
        stripe
        :header-cell-style="{
          background: '#FAFAFA',
          color: '#000000',
        }"
      >
        <el-table-column label="充值人" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="充值人手机号"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.mobile }}</span>
          </template>
        </el-table-column>
        <el-table-column label="充值时间" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.paymentDate }}</span>
          </template>
        </el-table-column>
        <el-table-column label="订单号" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.orderId }}</span>
          </template>
        </el-table-column>
      </el-table>

      <div class="table-footer">
        <el-pagination
          layout="total,sizes, prev, pager, next,jumper"
          background
          :total="itemTotal"
          :page-size="itemParams.size"
          @size-change="onItemSizeChange"
          :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="itemCurrentPage"
          @current-change="onItemCurrentPage"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { pickerOptions } from "@/db/objs";
import {
  getPackageOverView,
  getRechargeOrder,
} from "@/api/recharge/packageSetting.js";
export default {
  name: "PackageOverview",
  data() {
    return {
      pickerOptions,
      tableData: [],
      tableParams: {
        page: 1,
        pageSize: 10,
        merchantId: "",
        shopId: "",
      },
      total: 0,
      currentPage: 1,
      detailShow: false,
      dateToDate: [],
      itemParams: {
        kw: "",
        page: 1,
        size: 10,
        startTime: "",
        endTime: "",
      },
      itemData: [],
      itemTotal: 0,
      packageId: "",
      itemCurrentPage: 1,
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
    this.getPackageOverView();
  },
  methods: {
    // 充值明细
    getRechargeOrder() {
      let params = {
        kw: this.itemParams.kw,
        page: this.itemParams.page,
        size: this.itemParams.size,
        goodsId: this.packageId,
        dateFrom: this.itemParams.startTime,
        dateTo: this.itemParams.endTime,
      };
      getRechargeOrder(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.itemData = res.data.list;
          this.itemTotal = res.data.total;
          this.detailShow = true;
        }
      });
    },
    // 充值套餐概况
    getPackageOverView() {
      getPackageOverView(this.tableParams).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    reload() {
      this.itemParams.page = 1;
      this.itemCurrentPage = 1;
      this.getRechargeOrder();
    },
    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),
    // 选择日期
    goSelectDate() {
      if (this.dateToDate) {
        this.itemParams.startTime = this.dateToDate[0];
        this.itemParams.endTime = this.dateToDate[1];
      } else {
        this.itemParams.startTime = "";
        this.itemParams.endTime = "";
      }
      this.reload()
    },
    // 点击
    goClick(row) {
      this.packageId = row.packageId;
      this.getRechargeOrder();
    },
    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getPackageOverView();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.pageSize = size;
      this.getPackageOverView();
    },
    // 【监听】表格分页点击
    onItemCurrentPage(page) {
      this.itemParams.page = page;
      this.getRechargeOrder();
    },

    // 【监听】表格条数点击
    onItemSizeChange(size) {
      this.itemParams.pageSize = size;
      this.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
#packageOverview {
  padding: 0.15rem;
  box-sizing: border-box;
  .table-search {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.15rem;
  }
  .table-footer {
    margin-top: 0.1rem;
    display: flex;
    justify-content: flex-end;
  }
}
</style>